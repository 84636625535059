import { http } from './config'
import authHeader from './auth-header';

export default {

    lista: (empresa, situacao) => {
       /*
        const st = http.get('contratos', { headers: authHeader() })
        const s = st.then(value => {
            alert.log("this gets called after the end of the main stack. the value received and returned is: " + value);
            return value;
        });
        */
        return http.get('contratos/'+empresa + '/'+ situacao, { headers: authHeader() })
        //return axios.get(API_URL + 'user', { headers: authHeader() });

    },

    abrir: (id) => {
        return http.get('contrato', id)
    },

    update: (id) => {
        return http.get('update/'+ id, { headers: authHeader() })
    },

    salvar: (contrato) => {
        return http.post('contrato', contrato)
    },

    downloadFicha: (id) => {
        const params = new URLSearchParams([['id', id]])
        return http.get('download_ficha', { params }, { responseType: 'blob' },
        )
    },

    downloadZip: (id) => {
        const params = new URLSearchParams([['id', id]])
        return http.get('download_zip', { params }, { responseType: 'blob' },
        )
    },

}
